
/* for LandingRouter.js */
.lp2_container {
    display: flex;
    width: 100%;  
    margin: 0 auto; 
    overflow: hidden;
} 
.lp2_menu {
    flex: 300px;
    background: #36364C; 
} 
.lp2_body {
    height: 100%;
    flex: calc(100% - 300px); 
    background:  #F5F4F5;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto;
    overflow-y: auto;  
    z-index: 2;
}

.fywText {
    position: fixed;
    text-align: center;
    padding: 0px 30px 5px 30px;
    background-color: #36364C;
    color: white;
    font-size: 32px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    right: 0;
    margin-right: 30px;
    z-index: 9;  
    transition: 0.3s;
    font-weight: bold;
}

@media only screen and (max-width: 1024px) {   
    .fywText {
        display: none;
        // width: 100%;
        // background: #fff0;
        // color: #fff;
        // margin-top: -50px;
        // left: 0;
        // right: unset;
        // margin-right: 0px;
        // padding: 0px;
        // z-index: 999;
    } 
    .lp2_container {
        display: unset; 
    }
    .lp2_body {  
        height: 100%;
        flex: 0 0 calc(100% - 50px); 
       /*  background-image: url("../../files/images/landingPage/lp2_bottomSM.png"); */
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain; 
        padding-top: 50px;
    }
}
/* end for LandingRouter.js */




/* for Landing.js */
.lp2_HomePage .HomePage {
    width: 100%; 
    height: 100vh;
    background: #36364C;
    position: relative;
    overflow: hidden;
}
.lp2_HomePage .heroAnimation{
    position: absolute;
    left: 0px;
    top: 0px; 
}
.lp2_HomePage .heroLayer {  
    height: 400px; 
    width: 17000px;
    background-repeat: repeat-x;
    background-clip: border-box;
    background-size: auto 100%;
    position: absolute;
}
 
.lp2_HomePage #heroLayer0{
    background-image: url("../../files/images/layer0.png");   
}
.lp2_HomePage #heroLayer1{
    background-image: url("../../files/images/layer1.png");
    animation: moveHeroDesktop1 125s infinite linear;
}
.lp2_HomePage #heroLayer2{
    background-image: url("../../files/images/layer2.png");
    animation: moveHeroDesktop2 100s infinite linear;
}
.lp2_HomePage #heroLayer3{
    background-image: url("../../files/images/layer3.png");
    animation: moveHeroDesktop3 75s infinite linear;
} 

@keyframes moveHeroDesktop1 {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-4680px, 0, 0); }
}
@keyframes moveHeroDesktop2 {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-4680px, 0, 0); }
}
@keyframes moveHeroDesktop3 {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-4680px, 0, 0); }
}


@media only screen and (max-width: 1024px) { 
    .lp2_HomePage .heroLayer {  
        height: 200px;
    } 
    @keyframes moveHeroMobile { 
        0% { transform: translate3d(0, 0, 0); }
        100% { transform: translate3d(-3744px, 0, 0); }
    }
}


/* hero content */
.heroContent {
    padding-top: 400px;
    width: 100%;
    display: flex; 
    flex-direction: column;
    margin: auto;
    align-items: center; 
    color: #fff;
    .heroP {
        margin: 20px;
        font-size: 1.5em; 
    }

    .logoImage {
        background-image: url("../../files/images/logoLW.png");
        background-repeat: no-repeat; 
        background-size: 185px; 
        width: 185px;
        height: 80px;
    }
    .selectBtnContainer {
        display: flex; 
        padding: 1rem 2rem;
        border: 2px solid #fff;
        border-radius: 4px;
        color: #36364C;
        font-size: 3rem;
        align-items: left;
        text-decoration: none;
        justify-content: center; 
        text-transform: uppercase;
        background-size: 300%;
        box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.75);
        transition: all 0.4s;
        background-image: linear-gradient(150deg, white 50%, #36364C 50%);
        &:hover {
            color: #fff;
            background-position: 100%;
            transform: translateY(0.2rem);
        }
        &:active {
            transform: translate(0rem, 0.5rem);
            box-shadow: 0px 10px 20px -15px rgba(0, 0, 0, 0.15);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .heroContent {
        padding-top: 200px;
    }
}
/* end hero content */
/* end for Landing.js */
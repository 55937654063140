.gameRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .game {  
        box-shadow:0 0 4px 0px #000;
        margin-bottom: 10px;
        width: 250px;
        background-color: #e2e1e5;
        margin: 10px;
        padding: 0px 30px 30px 30px;
        position: relative;
        h1 {
            margin-top: 0;
            text-align: center;
            font-size: 31px;
        }
        .made-by {
            position: absolute;
            bottom: 0;
            right: 0;
            color: #878787;
            font-weight: bold;
            font-size: 12px;
        }
        .gameImage {
            width: 100%;
            height: 180px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
        .img1 { background-image: url("../../files/images/games/paintDrop.png"); }
        .img2 { background-image: url("../../files/images/games/paintDrop.png"); }
        .img3 { background-image: url("../../files/images/games/wallGame.png"); }
        .img4 { background-image: url("../../files/images/games/spaceGame.png"); }
        .img5 { background-image: url("../../files/images/games/darts.png"); }
        .img6 { background-image: url("../../files/images/games/scribole.png"); }
        .img7 { background-image: url("../../files/images/games/soneRacing.png"); }
    }
    .gameButton { 
        font-size: 20px; 
        width: 100%; 
        margin-top: 20px;
        font-weight: bold;
        padding: 5px
    }
    .newGame {
        animation: highlight 2s infinite;
        box-shadow: 0 0 10px 0px #ff0000;
    } 
    @keyframes highlight {
        0% {box-shadow: 0 0 10px 0px #ff0000;}
        50% {box-shadow: 0 0 10px 0px #00ff00;}
        100% {box-shadow: 0 0 10px 0px #ff0000;}
    } 
    .newGame::before,
    .newGame::after {
        content: "";
        position: absolute;
        inset: -0.2rem;
        z-index: -1;
        background: linear-gradient(var(--angle), 
          #032146,  #C3F2FF, #b00);
        animation: rotate 10s linear infinite;
    }
    .newGame::after {
        filter: blur(10px);
    }
    @keyframes rotate {
        0%     { --angle: 0deg; }
        100%   { --angle: 360deg;
        }
    }
}

@media only screen and (max-width: 1919px) {
    .game {  
        flex: 30% 2;
    }
} 
@media only screen and (max-width: 1150px) {
    .game {  
        flex: 50% 2;
    }
}

@media only screen and (max-width: 724px) {
}
.loginContainer {
	text-align: center;
}  
.loginMiddle { 
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
.loginMiddle label {
	float: left;
	font-size: 20px;
}

.loginContent {
	width: 340px;
    padding: 0px 0px 50px 0px;
}
.loginContent h1 {
	margin-top: 0px;
}
.loginContent img {
	width: 200px;
}


.loginBtn {
    width: 100%;
    background-color: #fff;
    color: #36364C;
    height: 40px;
    border: 0px;
    font-size: 20px; 
    font-weight: bold;
    letter-spacing: 1px;  
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #36364C;
	-webkit-transition: all ease 0.4s;
	-moz-transition: all ease 0.4s;
	transition: all ease 0.4s;
	 
}
.loginBtn:hover { 
	box-shadow: inset 340px 0 0 0 #ACAEBA; 
	color: #fff;
}

.tabContainer {
    width: 340px;
	height: 60px;
	font-size: 22px;
}
.tab-item {
    width: 50%;
    height: 100%;
    float: left;
    cursor: pointer;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-weight: bold;
	transition: 0.3s; 
}
.tab-item.active { 
    text-align: center;
    color: #fff;
    font-weight: bold;
    letter-spacing: 3px;
	border-top: none;
	background: #fff;
    color: #36364C;
    opacity: 1;
}


@media only screen and (max-width: 677px) { 
	.tabContainer {
		width: 90%;
	}
	.loginContent {
		width: 90%;
		border-radius: 0px;
	}
	.loginBtn:hover {
		box-shadow: inset 0 0 0 0 #36364C;
		color: #36364C;
	}
}
#admin_navBar {
    width: 100%; 
    height: 100px; 
    display: flex; 
    transition: 0.3s;   
    top: 0;
    z-index: 10;
    opacity: 1;
    border-bottom: 1px solid #2e2e2e;
    background: #36364c;
}
.headerAnimate {
    background-color: #2e2e2e !important;
    height: 60px !important;
    opacity: 1  !important;
    box-shadow: 0px 0px 2px 2px white !important;
    position: fixed;
}

/*
#logo {
    height: 100px;
    width: 200px; 
    transition:0.3s;
    cursor: pointer;
    float: left; 
    background-image: url('../../../public/images/navImg.png');
    background-repeat: no-repeat;
    background-size: 140px;    
    background-position-y: 10px;
    background-position-x: 30px; 
}*/
/* .logoAnimate {
    height: 60px !important;
    width: 110px !important; 
    border-right: 1px solid #e2e2e2 !important;
    background-image: url('../../../public/images/navImg.png') !important;
    background-position-y: 4px !important;
    background-position-x: 13px !important;
    background-size: 80px !important; 
} */

#navBar {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

#navBar li { 
    display: flex; 
    justify-content: center;
    flex-direction: column;
    transition: 0.3s;
    font-weight: bold; 
    font-size: 21px;
    list-style-type: none; 
    cursor: pointer;
}

#navBar li:hover { 
    background: rgba(163, 163, 163, 0.5);
    color: #e2e2e2; 
}

#navBar a {
    text-decoration: none; 
    color: #e2e2e2; 
}

.navBarAnimate { 
    line-height: 62px !important;  
    font-size: 24px !important;
}

.navBarAnimate:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 3px solid #2e2e2e;
    transition: 0.3s;
}
.admNavBtn {
    padding: 0px 10px;
}
.navBarAnimate:hover:after {
    width: 100%;
} 

#navBarMobile {
    display: none;
} 

#admin_navBar .admin_side-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: rgba(26,28,29,0.95);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.active-admin_side-menu {
    left: 0px !important;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    padding-top: 100px;
}
#admin_navBar .admin_side-menu li {
    list-style-type: none;
}
#admin_navBar .admin_side-menu li:before {
    position: absolute;
    content: '';
    left: 15%;
    top: 20%;
    width: 65%;
    height: 4px;
    background-color: #e2e2e2;
    -webkit-transform: rotate3d(0,0,1,-15deg) scale3d(0,1,1);
    transform: rotate3d(0,0,1,-15deg) scale3d(0,1,1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
#admin_navBar .admin_side-menu a { 
    color: #e2e2e2;
    width: 50%;
    height: 80px;
    line-height: 80px;
    display: block;
    font-size: 2rem;
    font-weight: bold;
    padding: 0px;
    margin: 0px auto; 
    text-transform: uppercase;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    text-decoration: none;
    border-bottom: 1px solid white;
    cursor: pointer;
}
#nav-icon1 {
    width: 60px;
    height: 75px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    float: right;	
    z-index: 10;
}

#nav-icon1 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: #e2e2e2;
    border-radius: 9px;
    left: -15px;
    margin-top: 25px;
    opacity:1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.nav-iconAnimate {
    margin-top: 7px !important;
}

#nav-icon1 span:nth-child(1) {
    top: 0px;
}

#nav-icon1 span:nth-child(2) {
    top: 18px;
}

#nav-icon1 span:nth-child(3) {
    top: 36px;
}

#nav-icon1.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon1.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

@media only screen and (max-width: 1024px) {
    #navBar {
        display: none;
    }
    #navBarMobile {
        display: block;
        right: 0;
        position: absolute;
    }
} 

@media only screen and (max-width: 768px) {
    .admin_side-menu a {
        width: 100%;
        font-size: 26px;
        margin: auto;
        border: 0px;
        text-align: center;
    }
}
.lp2_contactContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .contactContent {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 900px;
    } 
    .contactHeader p:nth-child(1){
        color: #36364C;
        text-align: center;
        margin: 20px auto;
        font-size: 42px;
    }
    .contactHeader p:nth-child(2){
        color: #36364C;
        text-align: center;
        margin: 20px auto;
        font-size: 20px;
    }
    .contactForm {
        .input-container {
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            margin-bottom: 15px;
            .icon {
                padding: 10px; 
                background: #ACAEBA;
                color: white;
                min-width: 50px;
                text-align: center;
                
            }
            .icon1 {
                background-image: url('../../files//images/inputn.png');
                background-size: 30px;
                background-repeat: no-repeat;
                background-position-x: 10px;
                background-position-y: 10px; 
            }
            .icon2 {
                background-image: url('../../files//images/inpute.png');
                background-size: 30px;
                background-repeat: no-repeat;
                background-position-x: 10px;
                background-position-y: 15px; 
            }
            .icon3 {
                background-image: url('../../files//images/inputm.png');
                background-size: 30px;
                background-repeat: no-repeat;
                background-position-x: 10px;
                background-position-y: 10px; 
            } 
            input {
                width: 100%;
                height: 50px; 
                display: flex;
                border:none;
                padding: 20px;
                font-size: 18px; 
                border-bottom: 1px solid #fff; 
                border-radius: 0px;
            }
            #message { 
                width: 100%;
                min-width: calc(100% - 50px);
                min-height: 250px; 
                display: flex;
                border:none;
                padding: 20px;
                font-size: 18px; 
                border-bottom: 1px solid #fff; 
                border-radius: 0px;
            }
        }
    }

    .submitContainer { 
        justify-content: center;
        padding: 20px;
        text-align: center;
        .button { 
            margin: auto;  
        }
    }
}
  
@media only screen and (max-width: 1024px) {
    .lp2_contactContainer {
        .contactContent { 
            width: 100%;
            height: 100%;
            padding: 60px 10px;
            margin-bottom: 60px;
            .input-container {
                .icon {
                    background-position-y: center;
                    background-size: 11px;
                    min-width: 30px;
                }
                input { 
                    height: 45px;
                    padding: 10px;
                }
                #message {
                    min-width: calc(100% - 40px);
                    padding: 10px;
                }
            }
        }
        .contactForm {
            input { 
                padding: 10px; 
            }
        }
        #message {  
            padding: 10px; 
        }
    } 
}
.gamesContainer {
    display: flex;  
    width: 100%;
    height: 100vh; 
    justify-content: center;
    flex-direction: column;
    max-width: 1300px;
    margin: auto;
}

@media only screen and (max-width: 1024px) {
    .gamesContainer {
        padding: 20px;
        height: 100%; 
    }
}
.navBar {
    display: flex;
    flex-direction: column; 
    height: 100vh;
    width: 300px;
    position: fixed;
    .topNavContainer {
        display: flex;
        flex-direction: column;
        .navitem-home { 
            display: flex;
            justify-content: center;
            padding: 15px;
        }
        img {
            width: 130px;
            margin: auto;
        }
        .navItem {
            width: 100%;
            display: flex;
            justify-content: center;
            color: white;
            padding: 15px;
            font-size: 24px;
            text-decoration: none;
    
            background: linear-gradient(to right, #acaeba1c 50%, #36364C 50%);
            background-size: 200% 100%;
            background-position: right bottom;
            transition: all .3s ease-out;
            &:hover {
                background-position: left bottom; 
            }
            &.active {
                background: #acaeba3b;
            }
        } 
    }
    .bottomNavContainer { 
        justify-content: flex-end;
        bottom: 0px;
        position: absolute; 
        width: 300px;
        text-align: center;
        img {
            width: 30px;
            margin: 10px;
        }
    }
} 





/* MOBILE NAV */
.navBarMobile {
    display: none;
    height: 50px;
    width: 100%;
    color:#fff;
    background: #36364c;
    z-index: 99;
    .logoMobile img {
        width: 80px;
        margin-top: 7px;
        margin-left: 5px;
    } 
    /* NAV-HAMBURGER */
    .navHamburger {
        width: 32px;
        height: 45px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        margin: auto 10px auto auto;
        span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: #fff;
            border-radius: 5px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 10px;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: 20px;
            }
            &:nth-child(4) {
                top: 30px;
            }

            .open span:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            .open span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            .open span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            .open span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        } 
    }
    /* end NAV-HAMBURGER */   
}  
/* end MOBILE NAV */

/* SIDENAV */ 
.navBarMobileItem {
    .navItem { 
        display: flex;
        color: #fff;
        font-size: 22px;
        font-weight: bold; 
        padding: 15px 40px; 
    }
    .side-menu {
        height: 100vh;
        display: flex;
        flex-direction: column;
        .subNavBox a {
            padding-left: 60px;
            font-size: 18px;
            background: #131e2a17;
        }
        .topNavContainer {
            display: flex;    
            flex-direction: column;
        }
        .bottomNavContainer { 
            width: 100%;
            justify-content: flex-end;
            bottom: 0px;
            position: absolute;  
            text-align: center;
            img {
                width: 30px;
                margin: 10px;
            }
        }
    }
} 
/* end SIDENAV */ 

.MuiPaper-root {
    background-color: #36364c !important;
    color: #F5F4F5 !important;
}

@media only screen and (max-width: 1024px) {
    .navBar {
        display: none; 
    } 
    .navBarMobile {
        display: flex;
        position: fixed;
    } 
} 
.projectsContainer{ 
    overflow: hidden;
    position: relative;

    .section:nth-child(odd) {
        background-color: rgba(172, 174, 186, 0.19);
    }
    .p_section { 
        display: flex;
        height: 100vh;
        max-width: 1600px;
        margin: auto;
        .p_sectionLeft { 
            display: flex; 
            flex-direction: column;
            width: 40%;
            padding:100px;
            justify-content: center;
            color: #36364c;
            .title {
                width: 100%;
                font-weight: bold;
                font-size: 30px;
            }
            .paragraph {
                width: 100%; 
                font-size: 20px;
                margin-bottom: 10px;
            } 
        }
        .p_sectionRight {
            background-repeat: no-repeat;
            /* background-size: contain; */
            background-position: center; 
            width: 60%;
            &.p1 {  background-image: url("../../files/images/projects/project1.png"); }
            &.p2 {  background-image: url("../../files/images/projects/project2.png"); }
            &.p3 {  background-image: url("../../files/images/projects/project3.png"); }
            &.p4 {  background-image: url("../../files/images/projects/project4.png"); }
            &.p5 {  background-image: url("../../files/images/projects/project5.png"); }
            &.p6 {  background-image: url("../../files/images/projects/project6.png"); }
            &.p7 {  background-image: url("../../files/images/projects/project7.png"); }
            &.p8 {  background-image: url("../../files/images/projects/project8.gif"); }
            &.p9 {  background-image: url("../../files/images/projects/project9.png"); }

            .divider { display: none; }
        }
    }


    @media only screen and (max-width: 1524px) {
        .p_section { 
            .p_sectionLeft { 
                padding: 40px;
            }
        } 
    }

    @media only screen and (max-width: 1024px) {
        .section {
            height: 100%;
        }
        .p_section {
            flex-direction: column-reverse;
            .p_sectionLeft {  
                width: 100%;
                height: 60%; 
                text-align: center;
                justify-content: start;
                .paragraph { 
                    font-size: 14px;
                }
                .button {  
                    width: 100%;
                }
            }
            .p_sectionRight { 
                display: flex;
                flex-direction: column; 
                background-size: contain;
                height: 40%;
                width: 100%; 
                .divider {
                    display: block;
                    width: 90%;
                    height: 5px;
                    background-color: #36364C;
                    margin: 5%;
                } 
            }
        }
    }
}

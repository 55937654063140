
.mainContainer {
    width: 100%;  
    height: 2000px; 
    background: #b5b5b5;

} 
.userInfo {
    width: 100%;
    float: left;
    background: #98b2da;
}

.searcher {
    width: 100%; 
    float: left;
}
.searcher input {
    width: 100%;
    height: 35px;
    font-size: 18px;
    border: 0;   
    padding: 20px;
    border-radius: 3px;
}



@media only screen and (max-width: 677px) {
    .hub {
        width: 100%;
        margin-bottom: 10px;
    }
}
.scrollContainer {
    position: fixed;
    bottom: 40px;
    right: 40px;
}
@keyframes dotTrail {
    0%, 100% { transform: translateY(0) scale(0.5); }
    50% { transform: translateY(20px) scale(1); }
}
@keyframes lineTrail {
    0%, 100% { transform: scaleY(1); }
    50% { transform: scaleY(0.5); }
}
.scroll-indicator {
    width: 2rem;
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 31px !important;
    border: 0;
    background: black;
    margin-left: 15px;
}
.line {
    width: 0.1rem;
    height: 40px;
    background: #27262d;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: top;
    animation: lineTrail 1s ease-in-out infinite;
}
.dot {
    width: 1rem;
    height: 1rem;
    background: #27262d;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) scale(0.5);
    animation: dotTrail 1s ease-in-out infinite;
}
.aboutContainer {
    height: 100vh; 
    .a_section {
        display: flex;
        height: 100%;
        max-width: 1600px;
        margin: auto;  
        .a_sectionLeft {
            display: flex; 
            flex-direction: column;
            width: 40%;
            padding:100px;
            justify-content: center;
            color: #36364c;
            font-size: 18px; 
            .title {
                width: 100%;
                font-weight: bold;
                font-size: 30px;
            }
            .paragraph {
                width: 100%; 
                font-size: 20px;
            }
        }
        .a_sectionRight {
            background-image: url("../../files/images/about/me.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center; 
            width: 100%; 
        }
        .sectionTitle {
            display: flex;
            padding: 10px 0px;
            font-size: 30px;
            font-weight: bold;
            color:#35354b;
            text-transform: uppercase; 
        } 
    }
 
    .educationContainer {
        display: flex; 
        margin: auto;
        padding: 20px;
        flex-direction: column; 
        background: #ACAEBA;
        color: #fff;
        .title {
            text-align: center;
            font-size: 30px;
        }
        img {
            width: 200px;
            margin: auto;
        }
    }
}
 
 
@media only screen and (max-width: 1524px) {
    .a_section { 
        .a_sectionLeft { 
            padding: 40px !important;
        }
    } 
}

@media only screen and (max-width: 1024px) {
    .aboutContainer{ 
        height: 100%; 
        .a_section { 
            display: flex;
            height: 100%;
            flex-direction: column-reverse;
            .a_sectionLeft {
                width: 100%;
                height: 50%;
                .button { 
                    margin: auto;
                }
            }
            .a_sectionRight { 
                display: flex;
                flex-direction: column;
                height: 50vh; 
                width: 100%;
            }
        }
    }
} 
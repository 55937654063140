.artContainer {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-width: 1600px;
    height: 100vh; 
    margin: auto;
    .filterContainer {
        width: 100%; 
        display: flex;
        .filter {
            width: 100%;
            padding: 15px;
            color: #fff;
            font-size: 1.5em;
            font-weight: bold;
            text-align: center;
            -webkit-user-select: none;
            user-select: none;
            transition: 0.2s;
            &.f_paintings {background-color: #6f5d70;}
            &.f_drawings {background-color: #3a677d;}
            &.f_costumes {background-color: #b46c14;}
            &.f_extras {background-color: #689977;}
            &:hover {
                cursor: pointer; 
            }
            &.active {
                opacity: 0.2;
                color: #000;
                box-shadow: inset 0px 0px 20px 1px #000;
            }
        }
    }
    .isLoading {
        width: 100%;
        display: flex;
    }
}
@media only screen and (max-width: 1024px) {
    .artContainer {
        height: auto; 
        flex-direction: column;
        .filterContainer {
            .filter {
                font-size: 1em;
            }
        }
    }
}